@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "./variables.scss";
@import "./navbar.scss";
@import "./modal.scss";
@import "./button.scss";
@import "./form.scss";
@import "./spinner.scss";

@font-face {
  font-family: "Segoe UI";
  src: local("Segoe UI"), url("../fonts/SegoeUI.ttf") format("truetype");
}

.user-select-none {
  user-select: none;
}

.meta-data-bold-2 {
  font-family: "Segoe UI";
  font-size: 12px;
  line-height: 16px;
  color: #c4c4c4;
}

.meta-data-regular-2 {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #c4c4c4;
}

.body-bold {
  font-family: "Segoe UI";
  font-size: 14px;
  line-height: 20px;
  color: #c4c4c4;
}

.header-bold {
  font-family: "Segoe UI";
  font-size: 18px;
  line-height: 24px;
  color: #c4c4c4;
}

.header-bold-2 {
  font-family: "Segoe UI";
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: #c4c4c4;
}

.header-bold-3 {
  font-family: "Segoe UI";
  font-size: 28px;
  line-height: 36px;
  color: #c4c4c4;
}

.header-bold-4 {
  font-family: "Segoe UI";
  font-size: 32px;
  line-height: 40px;
  color: #c4c4c4;
}

.header-bold-5 {
  font-family: "Segoe UI";
  font-size: 36px;
  line-height: 52px;
  color: #c4c4c4;
}

.body-regular {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #c4c4c4;
}

.subject-regular {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #c4c4c4;
}

.subject-bold {
  font-family: "Segoe UI";

  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 137% */

  color: #c4c4c4;
}

.header-regular-2 {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #c4c4c4;
}

.header-regular-3 {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 36px;
  color: #c4c4c4;
}

.tag {
  font-family: "Segoe UI";
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #c4c4c4;
}

.cursor-pointer {
  cursor: pointer;
}

.grecaptcha-badge { 
  visibility: hidden;
}

.recognize-field-wrapper {
  height: calc(100vh - 63px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropzone {
  border: 3px dashed #c4c4c4;
  margin-bottom: 30px;
  border-radius: 6px;
}

.background-grey {
  background-color: #cdcdcd;
}

.tpl-list-item {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fixed-toast {
  position: fixed;
  left: 45%;
  top: 20px;
  width: 20%;
}

* {
  font-family: "Segoe UI", sans-serif;
  box-sizing: border-box;
}

.no-results {
  text-align: center;
}
.no-result-spinner {
  text-align: center;
}

.template-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

html {
  background-color: map-get($greyPalette, grey1);
}
