@import "../../../scss/variables";
@import "../../../scss/mixins.scss";

.spinner {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 67px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: map-get($greyPalette, grey1);
    z-index: 3;

    @include use-media-query(non-desktop) {
      background-color: white;
    }

    &_flex-column {
      flex-direction: column;
    }

    &_pop-up {
      flex-direction: column;
      background-color: #fff;
      opacity: 0.8;
      z-index: 2;
    }
  }

  &__label {
    color: map-get($purplePrimaryPalette, purplePrimary);
    size: 12px;
  }
}

.results {
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin: auto;

    @include use-media-query(md) {
      width: 97%;
    }
  }
  &__pagination {
    background-color: map-get($greyPalette, grey1);
    padding-left: 10%;
    padding-right: 10%;

    @include use-media-query(md) {
      padding-left: 1.5%;
      padding-right: 1.5%;
    }

    @include use-media-query(non-desktop) {
      position: fixed;
      top: 67px;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: auto;
      padding: 0;
      background-color: white;
     
      .pagination {
        padding-left: 7.5px; 
      }
    }
  }

  @include use-media-query(non-desktop) {
    &__recognize-button {
      position: fixed;
      bottom: 10px;
      left: calc(50% - 62.5px);
      z-index: 1;
    }
  }
}
