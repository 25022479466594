@import "variables";
@import "mixins";

.btn {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.08em;
  box-shadow: none !important;
  outline: none !important;

  @include use-media-query(non-desktop) {
    height: 41px;
  }
}

.btn-primary {
  background-color: map-get($purplePrimaryPalette, purplePrimary);
  color: #ffffff;
  border-color: map-get($purplePrimaryPalette, purplePrimary);
  padding: 0.35rem 1rem;
  &:not(:disabled):hover {
    background-color: map-get($purplePrimaryPalette, primaryBtHover);
  }
  &:not(:disabled):active {
    background-color: map-get($purplePrimaryPalette, primaryBtClick);
  }
  &:disabled {
    background-color: map-get($greyPalette, grey4);
    border: none;
  }
}

.btn-secondary {
  background-color: transparent;
  color: map-get($purplePrimaryPalette, purplePrimary);
  border-color: map-get($purplePrimaryPalette, purplePrimary);
  padding: 0.35rem 1rem;
  &:not(:disabled):hover {
    background-color: transparent;
    border-color: map-get($purplePrimaryPalette, primaryBtHover);
    color: map-get($purplePrimaryPalette, primaryBtHover);
  }
  &:not(:disabled):active {
    background-color: transparent;
    border-color: map-get($purplePrimaryPalette, primaryBtClick);
    color: map-get($purplePrimaryPalette, primaryBtClick);
  }
  &:disabled {
    background-color: transparent;
    color: map-get($greyPalette, grey4);
    border-color: map-get($greyPalette, grey4);
  }
}

.btn-link {
  color: map-get($purplePrimaryPalette, purplePrimary);

  &:not(:disabled):hover {
    color: map-get($purplePrimaryPalette, primaryBtHover);
    text-decoration: none;
  }
  &:not(:disabled):active {
    color: map-get($purplePrimaryPalette, primaryBtClick);
  }
  &:disabled {
    color: map-get($greyPalette, grey4);
  }
}
