@import "src/scss/variables";

.instructions-modal {
  border: none;

  &__container {
    display: flex;
    justify-content: center;
    border: none;

    .modal-content {
      width: auto;
      border: none;
    }
  }

  &__header {
    padding: 12px 24px;
    border: none;
  }

  &__body {
    padding: 0;
    display: flex;
  }

  &__content {
    padding: 32.25px 74.5px 32.25px 54.5px;
    min-width: 760px;
  }

  &__page-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    min-width: 440px;

    &__item {
      padding: 16px 36px;
      border-bottom: 1px solid map-get($greyPalette, grey2);
      cursor: pointer;
      transition: background-color, 0.2s;

      &:first-child {
        border-top: 1px solid map-get($greyPalette, grey2);
      }

      &:hover {
        transition: background-color, 0.2s;
        background-color: #CED4E3;
      }

      &--active {
        background-color: #CED4E3;
      }
    }
  }
}
