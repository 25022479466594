@import "src/scss/variables";

.support-modal {
  &__container {
    display: flex;
    justify-content: center;
    min-width: 730px;

    .modal-content {
      width: auto;
      border: none;
    }
  }

  &__header {
    color: map-get($greyPalette, white);

    button {
      color: map-get($greyPalette, white);
      box-shadow: none;
      opacity: 1;
    }
  }

  &__content-container {
    display: flex;
    flex-direction: column;
    padding: 36px;

    &__description {
      display: flex;

      div {
        color: map-get($greyPalette, grey8);
        flex-shrink: 4;
        margin-left: 19px;
        font-weight: 600;
      }
    }
  }

  &__input-symbol {
    position: relative;

    input {
      padding-left: 18px;
    }

    &:before {
      position: absolute;
      top: 3px;
      color: gray;
      content: "$";
      left: 5px;
    }
  }

  &__set-paid-container {
    display: flex;
    flex-direction: column;
    margin-left: 150px;

    span {
      color: map-get($greyPalette, typeBlack);
    }
  }

  &__buttons-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__cancel-button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    margin-right: 8px;
    height: 25px;
    margin-bottom: 6px;
  }

  &__ok-button {
    min-width: 190px;
    text-transform: uppercase;
  }
}