@import '../../../scss/variables.scss';
@import '../../../scss/mixins.scss';


.size-tumbler {
  position: absolute;
  bottom: 12px;
  left: 12px;
  width: 110px;
  z-index: 2;
  display: flex;
  flex-direction: column;

  &-info {
    border-radius: 0;
    color: map-get($greyPalette, grey6);
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include use-media-query(non-desktop) {
      border-bottom: 0;
    }
  }

  .btn.btn-secondary {
    box-shadow: none;
    width: 48px;
    height: 48px;
    background-color: map-get($greyPalette, grey1);
    border: none;

    &:focus {
      box-shadow: none;
      border: none;
    }

    &:not(:disabled):hover {
      border: none !important;
    }

    &:active:focus {
      background-color: map-get($greyPalette, grey1) !important;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
    }
  }

  .form-control {
    font-size: 10px;
    padding: 0;
    font-weight: bold;
    height: 52px;
    width: 48px;
    background-color: map-get($greyPalette, white);
    border-left: none;
    border-right: none;
  }
}
