@import "../../scss/variables.scss";

.custom-search-drop-down-item:hover {
  background-color: map-get($purplePrimaryPalette, purpleHover);
}

.list-group {
  position: absolute;
  max-height: 300px;
  overflow-y: auto;
  top: 100%;
  right: -2px;
  box-shadow: 0 4px 14px rgba(100, 105, 112, 0.3);
  border-radius: 2px;
  z-index: 99;

  &__list-item {
    border: none;
    position: relative;
  }
}

.list-item {
  &__input {
    width: 100%;
    font-size: 14px;
    outline-color: map-get($purplePrimaryPalette, purplePrimary),
  }

  &__search-icon {
    position: absolute;
    top: 21px;
    right: 10px;
  }

  &__dropdown-item {
    cursor: pointer;
    color: #000;
    font-size: 14px;
  }

  &__no-data-placeholder {
    text-align: center;
    background-color: #FFF;
    color: map-get($greyPalette, grey5);
  }
}