@import "variables";
@import './mixins.scss';

.form-group {
  label {
    color: map-get($greyPalette , typeBlack);
    opacity: 0.6;
    font-size: 12px;
    margin-bottom: 2px; 
  }
  .is-invalid {
    border: 1px solid map-get($systemPalette, error);
  }
}

.form-check-input {
  background-color: transparent;
}

.form-control {
  border: 1px solid map-get($greyPalette, grey4);
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
  height: 32px;
  padding: 5px;
  background-color: map-get($greyPalette,white);
  box-shadow: none !important;

  @include use-media-query(non-desktop) {
      border-top: 0 !important;
      border-left: 0 !important;
      border-right: 0 !important;
      outline: 0 !important;
      min-width: auto;
      width: 100%;
  }

  &:not(:disabled):not(.is-invalid):not(.is-valid):hover {
    border: 1.5px solid map-get($purplePrimaryPalette, purplePrimary);
  }
  &:not(:disabled):not(.is-invalid):not(.is-valid):focus {
    border: 1.5px solid map-get($purplePrimaryPalette, purplePrimary);

  }
  &:disabled {
    border: 1.5px solid map-get($greyPalette, grey4);
    color: map-get($greyPalette, grey5)
  }
  &.is-invalid {
    background-image: none;
    border: 1.5px solid map-get($systemPalette, error);
  }
  &:not(.is-invalid).is-valid {
    background-image: none;
    border: 1.5px solid map-get($systemPalette, success);
  }
}
