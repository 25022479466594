@import '../../../scss/variables.scss';

.help {
  position: absolute;
  bottom: 12px;
  right: 12px;
  z-index: 99;
  width: 48px;
  height: 48px;
  border-radius: 4px;
  background-color: map-get($greyPalette, grey1);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
