@import "../../scss/variables.scss";

#dblClickEditTextEditOn {
        display: block;
        border: none;
        font-size: 14px;
        padding: 5px 15px 5px 10px;
        outline-color: map-get($purplePrimaryPalette, purplePrimary);
        background-color: transparent;
        width: 92.5%;
        line-height: 20px;
        font-weight: 400;
        margin-right: 0;
        resize: none;

       
}

#dblClickEditTextEditOff {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    padding: 5px 15px 5px 10px;

    &:hover {
        cursor: default;
    }
}