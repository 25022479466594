@import "../../scss/variables.scss";
@import "../../scss/mixins.scss";

.new-instance-row {
  position: fixed;
  top: 67px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: map-get($greyPalette, grey1);
  display: flex;
  align-items: center;
  justify-content: center;

  @include use-media-query(non-desktop) {
    padding-bottom: 6.25rem;
    &__non-desktop-warning {
      position: fixed;
      left: 1rem;
      right: 1rem;
      bottom: 4rem;
    }
  }

  &__pop-up {
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 4;
  }

  &__choose-file-button {
    background-color: map-get($purplePrimaryPalette, purplePrimary);
    color: white;
    border: 1px solid map-get($purplePrimaryPalette, purplePrimary);
    font-weight: 600;

    @include use-media-query(non-desktop) {
      position: fixed;
      left: 1rem;
      right: 1rem;
      bottom: 1rem;
      text-align: center;
    }
  }

  @include use-media-query(non-desktop) {
    background-color: white;
    &__non-desktop-warning {
      color: #848484;
    }
  }
}

.dropzone {
  position: fixed;
  top: 58px;
  left: 30px;
  right: 30px;
  bottom: 30px;
  outline: none;
  border: none;
  z-index: 2; //need to drag and drop working

  &_active {
    top: 88px;
    border: 3px dashed map-get($purplePrimaryPalette, purplePrimary);
    border-radius: 12px;
    background-color: rgba(86, 116, 236, 0.1);
  }
}

.black-text {
  color: map-get($greyPalette, typeBlack);
}

.grey-text {
  color: map-get($greyPalette, grey6);
}

.bold-text {
  font-weight: bold;
}

.close-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  fill: map-get($greyPalette, grey6);
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 5;
}
