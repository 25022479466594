@import "../../../scss/variables.scss";

.template-list {
  cursor: pointer;
  height: 370px;

  &__item {
    &:hover {
      border: 2px solid map-get($purplePrimaryPalette, purplePrimary);
    }

    position: relative;
    display: block;
    margin: 10px;
    width: 220px;
    height: 355px;
    background-color: white;
    border: 1px solid #dbdfe4;

    img {
      width: 100%;
      height: 277px;
      padding: 7.5px;
    }

    .list-group-item:hover {
      background-color: #f0f2f7;
      cursor: pointer;
    }
  }
}

.item {
  &__container {
    background-color: #fff;
    border-top: 1px solid #dbdfe4;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__icon-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__dropdown-container {
    position: absolute;
    left: 75px;
    top: 97%;
    right: 10px;
    z-index: 99;
    width: fit-content;
  }
}

.dropdown {
  &__item {
    border: none;
    cursor: pointer;

    span {
      margin-left: 8px;
    }
  }
}

.template-item {
  // border-radius: 6px;
  // button {
  //   width: 111px;
  //   height: 26px;
  //   padding: 0px;
  //   font-size: 12px;
  //   background-color: #dadada;
  //   color: black;
  // }
  // button:hover {
  //   background-color: black;
  //   color: white;
  // }
}

// .template-item:hover {
//   // cursor: pointer;
//   .template-item-hoverable-info {
//     display: flex;
//     justify-content: center;
//     flex-direction: column;
//     align-items: center;
//   }
// }

// .template-item-hoverable-info {
//   position: absolute;
//   display: none;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: rgba(122, 118, 118, 0.5);
//   border-radius: 2.5px;
// }

// .template-item-hoverable-info-delete-icon {
//   position: absolute;
//   top: 10px;
//   right: 10px;
// }

// .template-item-hoverable-info-delete-icon:hover {
//   opacity: 0.6;
// }
