@import "../../scss/variables";
@import "../../scss/mixins.scss";

.success-alert {
  position: fixed;
  top: 0;
  width: 100%;
  text-align: center;
}

.sign-form {
  &__row {
    height: 100vh;
    background-color: map-get($greyPalette, grey1);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__form {
    width: 500px;
    margin: 0 auto;
    border-radius: 4px;

    @include use-media-query(non-desktop) {
      min-width: auto;
      width: 100%;
      height: 100vh;
      display: flex;
      justify-content: space-between;
      background-color: white;
      flex-direction: column;
    }
  }

  &__footer {
    width: 100%;
    padding: 1rem;
    padding-top: 0;
    background-color: white;
  }

  @include use-media-query(non-desktop) {
    &__logo {
      position: absolute;
      top: 0.7rem;
      left: 1rem;
    }
    // &__footer {
    //   width: 100%;
    //   padding: 1rem;
    //   background-color: white;
    // }
  }

  &__header {
    background-color: map-get($purplePrimaryPalette, purplePrimary);
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    @include use-media-query(non-desktop) {
      position: relative;
      background-color: white;
      color: #202022;
      font-size: 12px;
      letter-spacing: 0.08em;
      text-align: center;
    }
  }

  &__body {
    background-color: map-get($greyPalette, white);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &__button {
    font-weight: 600;
    font-style: normal;
    line-height: 16px;
  }

  &__reset-link {
    font-size: 12px;
  }

  &__privacy-text {
    padding-top: 18px;
    font-size: 10px;
    line-height: 14px;
    text-align: right;
    color: map-get($greyPalette, grey9);
  }
}
