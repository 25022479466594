$purplePrimaryPalette: (
  purplePrimary: #5674ec,
  primaryBtClick: #2b50e4,
  primaryBtHover: #385ced,
  purplePrimary30: rgba(86, 116, 236, 0.3),
  purplePrimary10: rgba(86, 116, 236, 0.1),
  purpleClick: #cfd4e3,
  purpleHover: #f0f2f7,
);

$greyPalette: (
  typeBlack: #202022,
  grey10: #646870,
  grey9: #aaaaaa,
  grey8: #404040,
  grey7: #848484,
  grey6: #646970,
  grey5: #90949b,
  grey4: #c2c6cc,
  grey3: #dbdfe4,
  grey2: #e6e8ed,
  grey1: #f2f3f5,
  white: #ffffff,
  white80: rgba(255, 255, 255, 0.8),
  white40: rgba(255, 255, 255, 0.4),
);

$systemPalette: (
  error: #e22134,
  success: #1db977,
  warning: #f2c03f,
);

$small: 1024px;
$md: 768px;
