@import "../../scss/variables.scss";

.list-view {
  background-color: map-get($greyPalette, grey1);
  position: relative;
  min-height: 434px;
  .body-regular {
    color: map-get($greyPalette, typeBlack);
    cursor: pointer;
  }

  &__row {
    cursor: pointer;
    border-bottom: 1px solid transparent;

    &_selected {
      background-color: map-get($purplePrimaryPalette, purpleClick) !important;
      border-bottom: 1px solid map-get($purplePrimaryPalette, purplePrimary);
    }
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: map-get($greyPalette, grey2);
  }

  tbody {
    tr:hover {
      background-color: map-get($purplePrimaryPalette, purpleClick) !important;
      border-bottom: 1px solid map-get($purplePrimaryPalette, purplePrimary);

      span#group {
        color: map-get($greyPalette, grey3);
        background-color: map-get($purplePrimaryPalette, purplePrimary);
      }
    }
  }

  span {
    font-size: 14px;
    color: map-get($greyPalette, grey5);
  }

  span#group {
    color: map-get($purplePrimaryPalette, purplePrimary);
    padding: 5px;
    background-color: map-get($greyPalette, grey3);
    font-size: 10px;
  }

  .table {
    background-color: map-get($greyPalette, grey1);
    margin: 0;
  }

  thead {
    th {
      vertical-align: auto;
      font-size: 12px;
      color: map-get($greyPalette, grey5);
    }

    th:first-child {
      font-size: 14px;
    }
  }

  /*Custom checkbox css */
  .checkbox-container {
    display: block;
    position: relative;
    padding-right: 10px;
    // padding-left: 35px;
    // margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 5px;
    left: 2.5px;
    height: 12.5px;
    width: 12.5px;
    background-color: #eee;
    border: 1px solid #646970;
  }

  /* On mouse-over, add a grey background color */
  .checkbox-container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .checkbox-container input:checked ~ .checkmark {
    background-color: #5674ec;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: 3.5px;
    top: 0;
    width: 4px;
    height: 9px;
    border: solid white;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
  }

  /* Style the checkmark/indicator */
  .checkbox-container.main.intermediate .checkmark:after {
    left: 2px;
    top: 0;
    width: 7px;
    height: 6px;
    border: solid white;
    border-width: 0 0 1px 0;
    transform: none;
  }

  .checkbox-container.main.active .checkmark:after {
    left: 3.5px;
    top: 0;
    width: 4px;
    height: 9px;
    border: solid white;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
  }
}
