@import '../../scss/variables';
@import '../../scss/mixins.scss';

.wrapper {
  &__container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.spinner {
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 67px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: map-get($greyPalette, grey1);
  }
}

.main-row {
  position: fixed;
  left: 0;
  flex-wrap: nowrap;
  @include use-media-query(non-desktop) {
    flex-wrap: wrap;
    overflow: auto;
  }
  right: 0;
  bottom: 0;
  top: 67px;

  @include use-media-query(non-desktop) {
    &__save-button {
      width: 50%;
      .btn {
        width: 100%;
      }
    }
    &__cancel-all-actions-button {
      width: 50%;
    }
  }

  &__buttons-container {
    display: flex;
    flex: 1 1 auto;
    align-items: flex-end;
    justify-content: flex-end;
    background-color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    @include use-media-query(non-desktop) {
      justify-content: space-between;
    }
  }

  &__left-column {
    overflow: hidden;
    position: relative;
    height: 100%;
    padding-right: 0;
    background-color: map-get($greyPalette, grey1);
    order: 0;
    flex-basis: 370px;
    flex-shrink: 0;
    flex-grow: 0;

    @include use-media-query(non-desktop) {
      order: 1;
      height: 50%;
      flex: 0 1 auto;

      min-height: 260.5px;
    }
  }

  &__right-column {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: map-get($greyPalette, grey3);
    height: 100%;
    order: 1;
    flex-shrink: 1;
    flex-grow: 1;
    @include use-media-query(non-desktop) {
      order: 0;
      height: 50%;
      flex: 0 1 auto;

      min-height: 260.5px;
    }
  }
}

.left-column {
  &__upper-div {
    display: flex;
    position: relative;
    justify-content: space-between;
    border-top: 1px solid map-get($greyPalette, grey3);
    border-bottom: 1px solid map-get($greyPalette, grey3);
    background-color: #FFF;
    padding: 1rem;

    @include use-media-query(non-desktop) {
      border: none;
      padding: 0.5rem;
    }
  }

  &__template-name {
    font-size: 18px;
    font-weight: bold;
  }

  &__file-name {
    color: map-get($greyPalette, grey5);
    font-size: 12px;
    background-color: #FFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__group-pop-up {
    font-size: 12px;
    @include use-media-query(non-desktop) {
     font-size: 14px;
    }
    color: map-get($greyPalette, grey6);

    &_active {
      color: map-get($purplePrimaryPalette, purplePrimary);
    }
  }

  &__spinner-container {
    text-align: center;
    background-color: #FFF;
  }
}

.spinner-container {
  &__label {
    color: map-get($purplePrimaryPalette, purplePrimary);
    size: 12px;
  }
}

.upper-div {
  &__label {
    font-size: 18px;
    font-weight: bold;
  }
}

.recognition-manage-actions-panel {
  z-index: 99;
  position: absolute;
  top: 12px;
  right: 10px;
}
.ref-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 58px;
}

