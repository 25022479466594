@import "../../../scss/variables";

.language-selector {
  &__container {
    display: flex;
    border: none;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.25rem 1.5rem;
    margin: 0;

    &:active {
      //Override bootstrap style
      background: none !important;
    }
  }

  &--open {
    background: #f8f9fa;
  }

  &__info {
    font-weight: normal;
    font-size: 14px;
    color: map-get($greyPalette, grey6);
    letter-spacing: normal;
  }

  &__icon {
    cursor: pointer;
    transition: transform 0.3s;

    &_open {
      transform: rotate(180deg);
    }
  }
}

.language-list {
  border: none !important;

  &__item {
    display: flex;
    align-items: center;

    color: map-get($greyPalette, grey6);
    font-size: 14px;

    &_selected {
      padding-left: 0;
    }

    &:focus {
      outline: none;
    }

    &:active {
      //Override bootstrap style
      background: #f8f9fa !important;
      color: map-get($greyPalette, grey6) !important;
    }
  }
}